import React from 'react';
import 'rc-slider/assets/index.css';
import TooltipSlider from './TooltipSlider';

import { LangServices } from '@lainaedge/platformshared';
import { LookupType } from '@lainaedge/platformshared/lib/types/DataDictionaryType';
import { QNS_VALUE, UNAVAILABLE_VALUE } from 'Common/constants';

import FormInput from './FormInput';

/**
 * SliderInput component
 *
 * @component SliderInput
 * @category FormElements
 */
export default class SliderInput extends FormInput
{
  /**
   * Toggles the value of a field between 'Yes' or 'No'.
   *
   * @param field - Points to a field.
   * @returns Void
   */
  handleChangeValue = (value: string) =>
  {
    const field = this.props.formProps.field;
    this.setValue(field, value);
  };

  getLookupItemText = (item: any) =>
  {
    const lang = LangServices.currentLanguage.short_code;
    return item['text_' + lang];
  }

  /**
   * Renders SliderInput class component.
   */
  public render(): JSX.Element
  {
    const step = this.props.formProps.step;

    /** Initialize the value of the state from the database value. */
    const field = this.props.formProps.field;

    const is_on_modal = this.props.formProps.is_on_modal;
    const is_disabled = this.props.formProps.is_disabled;

    let useDefault = true;
    let max = 0;
    let min = 1;

    let className = '';
    if (
      this.state.error &&
      !is_disabled &&
      !this.props.formProps.field.enabled &&
      (is_on_modal || !step.is_edit_mode)
    )
      className += ' is-invalid';

    const labels: any = {};
    let list: any[] = [];
    if (step.tableDef && step.dataDictionary)
    {
      const name = step.tableDef.getFieldLookupName(field.field);

      /** Represents a lookup table */
      list = step.dataDictionary.getLookupTable(name);

      if (list && list.length)
      {
        list.map((option) =>
        {
          labels[option.code] = this.getLookupItemText(option);
          if (Number(option.code) > max)
          {
            max = Number(option.code);
          }
          if (Number(option.code) < min)
          {
            min = Number(option.code);
          }
        });
        useDefault = false;
      }
    }

    const fieldValue = this.isEditFieldOnModal()
      ? this.props.formProps.edit_values[field.field]
      : Number(this.state.myFieldValue);
    return (
      <>
        <div>
          <div className="custom-slider">
            <TooltipSlider
              min={useDefault ? 0 : min}
              max={useDefault ? 5 : max}
              className={className}
              value={[QNS_VALUE, UNAVAILABLE_VALUE].includes(fieldValue) ? '' : fieldValue}
              onChange={(value: any) =>
              {
                if (this.isEditFieldOnModal())
                {
                  this.props.formProps.handleChangeEditValues(field, value);
                } else
                {
                  this.handleChangeValue(value);
                }
              }}
              marks={useDefault ? { 1: 'Very Easy', 2: 'Easy', 3: 'Medium', 4: 'Hard', 5: 'Very Hard' }
                : labels}
              included={false}
              dots={false}
              disabled={this.isFieldDisabled()}
              handleStyle={{
                borderColor: '#597df7',
                height: 28,
                width: 28,
                backgroundColor: '#597df7',
                opacity: 1,
                marginTop: '-8px'
              }}
              railStyle={{
                backgroundColor: '#e6e6e6',
                height: '12px',
                boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.4)',
              }}
              dotStyle={{
                background: 'transparent',
                color: 'transparent',
                border: 'none'
              }}
              tipFormatter={(e) =>
              {
                const lookupItem = list.find((item) => item.code == e);
                return lookupItem?.custom_code ? lookupItem?.custom_code : lookupItem?.code;
              }}
              tipProps={{}}
            />
          </div>
        </div>
        {this.renderQnsAndUnavailableSwitches()}
        {this.renderValidationError()}
      </>
    );
  }
}
