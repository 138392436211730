import React, { useEffect, useRef, useState } from 'react';
import { Cookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import { Card, Col, Container, Row } from 'reactstrap';

import { AxiosError } from 'axios';
import { myProjectName } from 'Common/constants';
import { useAuth } from 'Common/context/AuthContext';
import { authMagicCode } from 'Common/services';
import { LangServices } from '@lainaedge/platformshared';

const cookies = new Cookies();
const langService = LangServices.instance();

/**
 * HubMagicLoginPage component.
 *
 * @remarks
 * HubMagicLoginPage that parses and runs the command
 *
 * @component HubMagicLoginPage
 * @category Page
 */
const HubMagicLoginPage = (): JSX.Element =>
{
  const { clearState, setUser, setUserType, setIsAuthenticated } = useAuth();
  const { code } = useParams() as { code: string };
  const [error, setError] = useState('');

  const mounted = useRef(false);
  useEffect(() =>
  {
    mounted.current = true;
    return () =>
    {
      mounted.current = false;
    };
  });

  useEffect(() =>
  {
    login();
  }, []);

  const login = async () =>
  {
    clearState();
    try
    {
      const res = await authMagicCode(code, myProjectName);

      if (res && res.data)
      {
        const userObj = {
          username: res.data.username!,
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          email: res.data.username!,
          userType: 'coordinator',
          token: res.data.authToken!,
          role: res.data.role?.name,
          groups: res.data.groups!,
        };
        cookies.set('authUser', userObj, { path: '/' });
        cookies.set('token', res.data.authToken, { path: '/' });
        cookies.set('type', 'coordinator', { path: '/' });
        cookies.set('authOrigin', 'Hub', { path: '/' });
        setUser(userObj);
        setIsAuthenticated(true);
        setUserType('coordinator');
      }
    } catch (error)
    {
      const err = error as AxiosError;
      if (err.response && err.response.data)
      {
        setError(err.response.data.message ?? langService.Translate('Username or password is not correct'));
      }
    }

    // component is unmounted
    if (!mounted.current) return;
  };

  return (
    <React.Fragment>
      <div className="container-fluid">
        <Container>
          <Row className="justify-content-center">
            <Col md={12}>
              <h4 className="flex-box-reverse"></h4>
              <Card className="overflow-hidden">
                <div className="p-2 text-center">{error && <div>{error}</div>}</div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default HubMagicLoginPage;
