import React, { Component } from 'react';

import { DataTools, StepForm, StringOptions, LangServices } from '@lainaedge/platformshared';
import { QNS_VALUE, UNAVAILABLE_VALUE } from 'Common/constants';

import { InputProps, InputState } from './types';

const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

const langService = LangServices.instance();

/**
 * FormInput component
 *
 * @component FormInput
 * @category FormElements
 */
export default class FormInput extends Component<InputProps, InputState> {
  constructor(props: InputProps)
  {
    super(props);

    /** Initialize the value of the state from the database value */
    const field = this.props.formProps.field;

    this.state = {
      myAlign: this.props.formProps.step.getValueAlign(field.field),
      myUnits: this.props.formProps.step.getValueUnits(field.field),
      myFieldValue: this.getValue(field),
      error: this.props.formProps.errors[field.field],
      warning: this.props.formProps.warnings ? this.props.formProps.warnings[field.field] : '',
    };
  }

  async componentDidUpdate(prevProps: InputProps)
  {
    const field = this.props.formProps.field;

    if (
      this.props.formProps.counter !== prevProps.formProps.counter ||
      this.props.formProps.field.field !== prevProps.formProps.field.field ||
      this.props.formProps.errors[field.field] !== prevProps.formProps.errors[field.field] ||
      this.props.formProps.warnings !== prevProps.formProps.warnings
    )
    {
      this.setState({
        myFieldValue: this.getValue(field),
        error: this.props.formProps.errors[field.field],
        warning: this.props.formProps.warnings ? this.props.formProps.warnings[field.field] : '',
      });
    }
  }

  /** Get the value from the record using getValueDatabase and return a clean/trimmed version */
  getValue = (field: StepForm.FieldInfo): string =>
  {
    const myValueDatabase = this.props.formProps.step.getValueDatabase(field.field);

    let value = '';
    if (myValueDatabase !== undefined && myValueDatabase !== null)
    {
      if (typeof myValueDatabase === 'string') value = myValueDatabase.trim();
      else if (myValueDatabase.toString) value = myValueDatabase.toString();
      else value = myValueDatabase;
    }
    return value;
  };

  /** Set the value that should appear in the editor to the string 'newValue' and
   * set the form value at the same time.  Skips setting the form value if the user
   * enters something ending in "." so that 0. or .03 or something similar can be entered.
   */
  setValue = (field: StepForm.FieldInfo, newValue: string) =>
  {
    this.setState({ myFieldValue: newValue });

    if (newValue.length > 0)
    {
      if (newValue.charAt(newValue.length - 1) === '.') return;
    }

    this.props.formProps.step.setValueFromUser(field.field, newValue);
  };

  /**
   * Returns true if edit field is on modal and is not disabled.
   *
   * @returns
   * Boolean
   */
  isEditFieldOnModal = () =>
  {
    const is_on_modal = this.props.formProps.is_on_modal;
    const is_disabled = this.props.formProps.is_disabled;

    return is_on_modal && !is_disabled;
  };

  /**
   * Returns true if edit mode is true and if is on modal is false
   *
   * @returns
   * Boolean
   */
  isEditMode = () =>
  {
    const step = this.props.formProps.step;
    const is_on_modal = this.props.formProps.is_on_modal;

    return !is_on_modal && step.is_edit_mode;
  };

  /**
   * Used to set new field value.
   *
   * @param field - Points to a field.
   * @param e - Used to take a value.
   * @returns Void
   */
  handleChangeText = (field: StepForm.FieldInfo, e: any) =>
  {
    const value = e.target.value;
    this.setValue(field, value);
  };

  /**
   * Cleans/trims value of a provided field and sets it as the new value.
   *
   * @param field - Points to a field.
   * @returns Void
   */
  handleTrimText = (field: StepForm.FieldInfo) =>
  {
    const value = this.getValue(field);
    this.setValue(field, value);
  };

  /**
   * Used to update value based on QNS Switch
   *
   * @returns Void
   */
  handleToggleQNS = () =>
  {
    const field = this.props.formProps.field;

    const fieldValue = this.state.myFieldValue;

    if (this.isEditFieldOnModal())
    {
      if (fieldValue == QNS_VALUE)
      {
        this.props.formProps.handleChangeEditValues(field, '');
        this.setState({ myFieldValue: '' });
      } else
      {
        this.props.formProps.handleChangeEditValues(field, QNS_VALUE);
        this.setState({ myFieldValue: QNS_VALUE });
      }
    } else
    {
      if (fieldValue == QNS_VALUE)
      {
        this.setValue(field, '');
      } else
      {
        this.setValue(field, QNS_VALUE);
      }
    }
  };

  /**
   * Used to update value based on UNAVAILABLE Switch
   *
   * @returns Void
   */
  handleToggleUnavailable = () =>
  {
    const field = this.props.formProps.field;

    const fieldValue = this.isEditFieldOnModal()
      ? this.props.formProps.edit_values[field.field]
      : this.state.myFieldValue;

    if (this.isEditFieldOnModal())
    {
      if (fieldValue == UNAVAILABLE_VALUE)
      {
        this.props.formProps.handleChangeEditValues(field, '');
        this.setState({ myFieldValue: '' });
      } else
      {
        this.props.formProps.handleChangeEditValues(field, UNAVAILABLE_VALUE);
        this.setState({ myFieldValue: UNAVAILABLE_VALUE });
      }
    } else
    {
      if (fieldValue == UNAVAILABLE_VALUE)
      {
        this.setValue(field, '');
      } else
      {
        this.setValue(field, UNAVAILABLE_VALUE);
      }
    }
  };

  /**
   * Renders units of the field field.
   */
  renderUnit = (): JSX.Element =>
  {
    if (this.state.myUnits)
    {
      return (
        <span className="input-group-append">
          <span className="input-group-text">
            <i>{this.state.myUnits}</i>
          </span>
        </span>
      );
    }
    return <></>;
  };

  /**
   *  Render Validaion error for the form field
   */
  renderValidationError(): JSX.Element
  {
    const step = this.props.formProps.step;
    const is_on_modal = this.props.formProps.is_on_modal;
    const is_disabled = this.props.formProps.is_disabled;

    if (is_disabled || this.props.formProps.field.enabled || (!is_on_modal && step.is_edit_mode))
    {
      return <></>;
    }
    return (
      <div className="col-md-12">
        {this.state.error && (
          <span className="validation-error">{htmlToReactParser.parse(this.state.error)}</span>
        )}
        {this.state.warning && (
          <span className="validation-warning">{htmlToReactParser.parse(this.state.warning)}</span>
        )}
      </div>
    );
  }

  /**
   * Returns true if edit mode is true and if is on modal is false
   *
   * @returns
   * Boolean
   */
  isFieldDisabled = () =>
  {
    const is_disabled = this.props.formProps.is_disabled;
    const field = this.props.formProps.field;
    const fieldValue = this.isEditFieldOnModal()
      ? this.props.formProps.edit_values[field.field]
      : this.state.myFieldValue;

    const isFieldDisabled =
      this.isEditMode() ||
      is_disabled ||
      this.props.formProps.hide_fields[field.field] ||
      this.props.formProps.field.enabled === false ||
      [QNS_VALUE, UNAVAILABLE_VALUE].includes(fieldValue) ||
      this.state.isOther;

    return isFieldDisabled;
  };

  /** Show an input normals range
  Assuming that the data dictionary has both nMin and nMax defined
  this will show a range next to the input field.
  If the value is outside that range it will show in yellow
  if that value is 4x the range limits then it shows in red.
  */
  renderReferenceRange = (): JSX.Element =>
  {
    /** Initialize the value of the state from the database value. */
    const field = this.props.formProps.field;
    const step = this.props.formProps.step;

    const fieldDetails = step.tableDef?.getField(field.field);
    const fieldOptions = new StringOptions(fieldDetails?.options);

    const showMin = fieldOptions.checkOption('nmin');
    const showMax = fieldOptions.checkOption('nmax');

    //  Nothing to show because no "nmin" and "nmax"
    if (!showMin && !showMax)
    {
      return <></>;
    }

    const fieldValue = DataTools.getNumber(
      this.isEditFieldOnModal()
        ? this.props.formProps.edit_values[field.field]
        : this.state.myFieldValue,
    );

    const nMin = DataTools.getNumber(fieldOptions.getValue('nmin', undefined)) || 0;
    const nMax = DataTools.getNumber(fieldOptions.getValue('nmax', undefined)) || 0;

    let iconMin = '';
    let iconMax = '';

    let colorLow = 'text-secondary';
    if (fieldValue != null && fieldValue != undefined)
    {
      colorLow = 'text-success';
      if (fieldValue < nMin || fieldValue > nMax)
      {
        colorLow = 'text-warning';
      }

      if (fieldValue * 4 < nMin || fieldValue > nMax * 4)
      {
        colorLow = 'text-danger';
        if (fieldValue * 4 < nMin) iconMin = '⚠️';
        else iconMax = '⚠️';
      }
    }

    return (
      <div className={`d-inline-block mr-2 col-2 ${colorLow}`}>
        {iconMin} {nMin} {langService.Translate('to')} {nMax} {iconMax}
      </div>
    );
  };

  /**
   * Renders QNS and Unavailable switches
   */
  renderQnsAndUnavailableSwitches = (): JSX.Element =>
  {
    /** Initialize the value of the state from the database value. */
    const field = this.props.formProps.field;
    const step = this.props.formProps.step;

    const fieldDetails = step.tableDef?.getField(field.field);
    const fieldOptions = new StringOptions(fieldDetails?.options);

    const showQns = fieldOptions.checkOption('qns');
    const showUnavailable = fieldOptions.checkOption('unavailable');

    const fieldValue = this.isEditFieldOnModal()
      ? this.props.formProps.edit_values[field.field]
      : this.state.myFieldValue;

    const is_disabled = this.props.formProps.is_disabled;

    const isFieldDisabled =
      this.isEditMode() ||
      is_disabled ||
      this.props.formProps.hide_fields[field.field] ||
      this.props.formProps.field.enabled === false;

    return (
      <>
        {this.renderReferenceRange()}
        {showQns && (
          <div className="d-inline-block mr-3 mb-1 custom-switch">
            <input
              type="checkbox"
              id={this.isEditFieldOnModal() ? 'e' + field.field + 'qns' : field.field + 'qns'}
              className="custom-control-input"
              checked={fieldValue == QNS_VALUE}
              disabled={isFieldDisabled}
              onChange={this.handleToggleQNS}
            />
            <label
              className="custom-control-label"
              htmlFor={this.isEditFieldOnModal() ? 'e' + field.field + 'qns' : field.field + 'qns'}
            >
              {langService.Translate('QNS')}
            </label>
          </div>
        )}
        {showUnavailable && (
          <div className="d-inline-block mr-3 custom-switch">
            <input
              type="checkbox"
              id={this.isEditFieldOnModal() ? 'e' + field.field + 'unavailable' : field.field + 'unavailable'}
              className="custom-control-input"
              checked={fieldValue == UNAVAILABLE_VALUE}
              disabled={isFieldDisabled}
              onChange={this.handleToggleUnavailable}
            />
            <label
              className="custom-control-label"
              htmlFor={this.isEditFieldOnModal() ? 'e' + field.field + 'unavailable' : field.field + 'unavailable'}
            >
              {langService.Translate('Unavailable')}
            </label>
          </div>
        )}
      </>
    );
  };

  /**
   * Renders FormInput class component.
   */
  public render(): JSX.Element
  {
    return <>{this.props.children}</>;
  }
}
