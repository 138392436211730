import React from 'react';
import { Helmet } from 'react-helmet';
import { Outlet, useLocation } from 'react-router-dom';

import { useData } from 'Common/context/DataContext';

/**
 * NonAuthLayout component.
 *
 * @remarks
 * Layout for guest users
 *
 * @component NonAuthLayout
 * @category Component
 */
const NonAuthLayout = () =>
{
  const location = useLocation();
  const { versionInfo, title_text } = useData();

  const capitalizeFirstLetter = (str: string) =>
  {
    return str.charAt(1).toUpperCase() + str.slice(2);
  };

  const currentPage = capitalizeFirstLetter(location.pathname);
  const sanitizedPateTitle = currentPage + ' | V' + versionInfo?.version + ' | ' + title_text

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{sanitizedPateTitle}</title>
        <meta name="title" content={sanitizedPateTitle} />
        <meta property="og:title" content={sanitizedPateTitle} />
      </Helmet>
      <Outlet />
    </React.Fragment>
  );
};

export default NonAuthLayout;
