import React, { useEffect, useRef, useState } from 'react';
import { Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import { Alert, Card, CardBody, Col, Container, Label, Row, Spinner } from 'reactstrap';

import { LangServices } from '@lainaedge/platformshared';
import { AuthResult } from '@lainaedge/platformshared/src/types/AuthResult';
import logo from 'Common/assets/images/logo-laina.png';
import vector2 from 'Common/assets/images/vector2.png';
import { myProjectName, myServerEnv } from 'Common/constants';
import { useAuth } from 'Common/context/AuthContext';
import { useData } from 'Common/context/DataContext';
import { login } from 'Common/services';
import { ILoginFormFields } from 'Common/types';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import packageJson from '../../../package.json';

const cookies = new Cookies();
const langService = LangServices.instance();

/**
 * Authentication/Login component.
 *
 * @remarks
 * Page where data monitor can log in
 *
 * @component Login
 * @category Page
 */
const Login = (): JSX.Element =>
{
  const { versionInfo, dashTitle } = useData();
  const { setUserType, setUser, error, setError, clearState, setIsAuthenticated } = useAuth();
  const [isSubmitting, SetIsSubmitting] = useState(false);

  const mounted = useRef(false);

  useEffect(() =>
  {
    mounted.current = true;
    return () =>
    {
      mounted.current = false;
    };
  });

  async function handleValidSubmit(values: ILoginFormFields)
  {
    clearState();
    SetIsSubmitting(true);
    const resp: AuthResult = await login(values);

    if (resp.isValidLogin)
    {
      const userObj = {
        username: resp.username!,
        firstName: resp.firstName,
        lastName: resp.lastName,
        email: resp.username!,
        userType: 'coordinator',
        token: resp.authToken!,
        role: resp.role,
        groups: resp.groups!,
      };
      cookies.set('authUser', userObj, { path: '/' });
      cookies.set('token', resp.authToken, { path: '/' });
      cookies.set('type', 'coordinator', { path: '/' });
      cookies.set('authOrigin', 'Trial', { path: '/' });
      setUser(userObj);
      setIsAuthenticated(true);
      setUserType('coordinator');
    } else
    {
      setError(resp.errorMessage ? langService.Translate(resp.errorMessage) : langService.Translate('Username or password is not correct'));
    }

    // component is unmounted
    if (!mounted.current) return;
    SetIsSubmitting(false);
  }

  const handleClearState = () =>
  {
    clearState();
  };

  const titleParts = dashTitle.split('(');

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="login-page account-pages pt-sm-3">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="login-header">
                  <div className="title">
                    <img src={logo} alt="" className="img-fluid" />
                  </div>
                  <img src={vector2} alt="" className="img-fluid" />
                </div>
                <div className="card-body pb-0">
                  <div className="text-darkblue p-2">
                    <h5 className="text-darkblue">{titleParts[0]}</h5>
                    <p className="mb-0">{titleParts.length === 2 && '(' + titleParts[1]}</p>
                  </div>
                </div>
                <CardBody className="">
                  <div className="p-2">
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        username: '',
                        password: '',
                      }}
                      validationSchema={Yup.object().shape({
                        username: Yup.string().required(langService.Translate('Enter Username')),
                        password: Yup.string().required(langService.Translate('Enter Password')),
                      })}
                      onSubmit={(values) =>
                      {
                        handleValidSubmit(values);
                      }}
                    >
                      {({ errors, touched }) => (
                        <Form className="form-horizontal">
                          {error && error ? <Alert color="danger">{error}</Alert> : null}
                          <div className="form-group">
                            <Label for="username" className="form-label">
                              {langService.Translate('Username')}
                            </Label>
                            <Field
                              name="username"
                              id="username"
                              type="input"
                              required
                              placeholder={langService.Translate('Enter Username')}
                              className={
                                'form-control' +
                                (errors.username && touched.username ? ' is-invalid' : '')
                              }
                            />
                            <ErrorMessage
                              name="username"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="form-group">
                            <Label for="password" className="form-label">
                              {langService.Translate('Password')}
                            </Label>
                            <Field
                              name="password"
                              id="password"
                              type="password"
                              required
                              placeholder={langService.Translate('Enter Password')}
                              className={
                                'form-control' +
                                (errors.password && touched.password ? ' is-invalid' : '')
                              }
                            />
                            <ErrorMessage
                              name="password"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="mt-3">
                            <button
                              className="btn btn-login btn-block waves-effect waves-light save-btn"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              {langService.Translate('Log In')}
                              {isSubmitting && <Spinner className="spinner" />}
                            </button>
                          </div>

                          {myServerEnv === 'uat' && (
                            <div className="mt-3 login-page-small">
                              This is the User Acceptance Test (UAT) Environment. If you are at the
                              site or looking for production data{' '}
                              <a
                                className="login-notice"
                                href={'https://prod.' + myProjectName + '.trial.lainaent.com/'}
                              >
                                Click Here.
                              </a>
                            </div>
                          )}

                          {myServerEnv === 'prod' && (
                            <div className="mt-3">
                              This is the Production Server for {dashTitle}
                            </div>
                          )}

                          <div className="mt-4 text-center">
                            <Link
                              to="/forgot-password"
                              className="text-muted"
                              onClick={handleClearState}
                            >
                              <i className="fa-solid fa-lock-keyhole"></i> {langService.Translate('Forgot your password')}?
                            </Link>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
              <div className="text-center login-page-small">
                CPS : {versionInfo?.clientPlatformSharedVersion}, SPS :{' '}
                {versionInfo?.serverPlatformSharedVersion}, UI : {packageJson.version}, TDD:{' '}
                {versionInfo?.serverTDDVersion}, V: {versionInfo?.version}
                {navigator.webdriver ? ', WebDriver Detected' : ''}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
