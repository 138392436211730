import React, { useEffect, useState } from 'react';

import { DEFAULT_PROD_AUTH_TIMEOUT, DEFAULT_UAT_AUTH_TIMEOUT, myServerEnv } from 'Common/constants';
import { useAuth } from 'Common/context/AuthContext';

/**
 * IdleWatch component.
 *
 * @remarks
 * Log the user out if idle for some time
 *
 * @component IdleWatch
 * @category Component
 */
const IdleWatch = () =>
{
  const { logout, isAuthenticated } = useAuth();
  const [timestamp, setTimestamp] = useState(new Date().getTime());

  const timeout: number = process.env.REACT_APP_IDLE_TIMEOUT
    ? parseInt(process.env.REACT_APP_IDLE_TIMEOUT)
    : myServerEnv == 'prod'
      ? DEFAULT_PROD_AUTH_TIMEOUT
      : DEFAULT_UAT_AUTH_TIMEOUT;

  useEffect(() =>
  {
    const timer = setTimeout(() =>
    {
      if (isAuthenticated)
      {
        logout();
      }
    }, timeout);

    return () =>
    {
      clearTimeout(timer);
    };
  }, [timestamp, timeout, logout]);

  useEffect(() =>
  {
    const eventListener = () =>
    {
      setTimestamp(new Date().getTime());
    };
    document.addEventListener('click', eventListener);
    document.addEventListener('keydown', eventListener);

    return () =>
    {
      document.removeEventListener('click', eventListener);
      document.removeEventListener('keydown', eventListener);
    };
  }, []);

  return <React.Fragment />;
};

export default IdleWatch;
