import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardText, CardTitle, Col, Row } from 'reactstrap';

import { StepGroup } from '@lainaedge/platformshared';
import { isJson } from 'Common/helpers';
import styled from 'styled-components';

const StatsBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-y: hidden;
  overflow-x: auto;
  margin-bottom: 8px;

  .d-table {
    border-spacing: 0px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;

/**
 * Props for [[`PageGroup`]] component
 */
export interface PageGroupProps
{
  /** Array of components */
  components: JSX.Element[];
  /** Step data */
  step: StepGroup;
}

/**
 * PageGroup component
 *
 * @component PageGroup
 * @category PageElements
 */
export default class PageGroup extends Component<PageGroupProps> {
  constructor(props: PageGroupProps)
  {
    super(props);

    this.state = {};
  }

  /**
   * Renders Card
   * @returns {Promise<JSX.Element>}
   */
  renderCard = (cardIconClassName: string, cardType: string) =>
  {
    const { step, components } = this.props;

    return (
      <Row>
        <Col md={12}>
          <Card outline color={cardType} className="border">
            <CardHeader className="bg-transparent">
              <h5 className={`my-0 text-${cardType}`}>
                <i className={`fa-solid ${cardIconClassName} mr-3`}></i>
                {isJson(step.text) ? JSON.parse(step.text)?.name : step.text}
              </h5>
            </CardHeader>
            <CardBody data-testid={`${cardType}-card`}>
              {components}
              {/* <CardTitle className="mt-0"></CardTitle>
              <CardText></CardText> */}
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
  /**
   * Renders PageGroup class component.
   */
  public render(): JSX.Element
  {
    const { step, components } = this.props;

    if (step.options.checkOption('SuccessCard'))
    {
      return this.renderCard('fa-check-double', 'success');
    }
    if (step.options.checkOption('PrimaryCard'))
    {
      return this.renderCard('fa-bullseye-arrow', 'primary');
    }
    if (step.options.checkOption('InfoCard'))
    {
      return this.renderCard('fa-circle-info', 'info');
    }
    if (step.options.checkOption('WarningCard'))
    {
      return this.renderCard('fa-triangle-exclamation', 'warning');
    }
    if (step.options.checkOption('ErrorCard') || step.options.checkOption('DangerCard'))
    {
      return this.renderCard('fa-bolt', 'danger');
    }
    if (step.options.checkOption('SecondaryCard'))
    {
      return this.renderCard('fa-cloud', 'secondary');
    }
    if (step.options.checkOption('DarkCard'))
    {
      return this.renderCard('fa-circle-xmark', 'dark');
    }
    if (step.options.checkOption('LightCard'))
    {
      return this.renderCard('fa-truck-fast', 'light');
    }
    if (step.options.checkOption('BasicCard'))
    {
      return this.renderCard('fa-heart', 'basic');
    }
    if (step.options.checkOption('Toolbar'))
    {
      return (
        <Row>
          <Col md={12}>
            <div className="tool-bar" style={{ paddingTop: '8px', paddingBottom: '18px' }}>
              {components}
            </div>
          </Col>
        </Row>
      );
    }
    if (step.options.checkOption('ButtonBar'))
    {
      return (
        <Row>
          <Col md={12}>
            <div
              className="btn-group btn-group-toggle"
              data-testid="button-bar"
              data-toggle="buttons"
            >
              {components}
            </div>
          </Col>
        </Row>
      );
    }
    if (step.options.checkOption('TabBar'))
    {
      return (
        <Row>
          <Col md={12}>
            <div className="tab-group tab-group-toggle" data-testid="tab-bar" data-toggle="tabs">
              {components}
            </div>
          </Col>
        </Row>
      );
    }
    if (step.options.checkOption('Bullets'))
    {
      return (
        <Row>
          <Col md={12}>
            <div style={{ paddingLeft: '40px', paddingTop: '8px', paddingBottom: '18px' }}>
              {components}
            </div>
          </Col>
        </Row>
      );
    }
    if (step.options.checkOption('ButtonMenu'))
    {
      return (
        <Row>
          <Col md={12}>
            <div className="button-menu">
              <table className="button-list-table table-nowrap table-centered table-borderless table">
                <tbody>{components}</tbody>
              </table>
            </div>
          </Col>
        </Row>
      );
    }
    if (step.options.checkOption('FormFieldGroup'))
    {
      return <></>;
    }
    if (step.options.checkOption('StatsBar'))
    {
      return (
        <StatsBar>
          <Col md={12} className="d-table">
            {components}
          </Col>
        </StatsBar>
      );
    }
    return (
      <Row data-testid={'pagegroup'}>
        <Col md={12}>{components}</Col>
      </Row>
    );
  }
}
