import React, { useEffect, useRef, useState } from 'react';
import { Cookies } from 'react-cookie';
import { useParams, useNavigate } from 'react-router-dom';
import { Alert, Label, Spinner } from 'reactstrap';

import { LangServices, DataServices } from '@lainaedge/platformshared';
import { ParticipantAuthResult } from '@lainaedge/platformshared/src/types/ParticipantAuthResult';
import { useAuth } from 'Common/context/AuthContext';
import Layout from 'Common/layout/StudyNonAuthLayout';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

const cookies = new Cookies();

const langService = LangServices.instance();
const dataService = DataServices.instance();

interface ISetPasswordFormFields
{
  password: string;
  password_confirmation: string;
}

/**
 * Participant/SetPassword component.
 *
 * @remarks
 * Page where participant sets the password for the first time
 *
 * @component SetPassword
 * @category Page
 */
const SetPassword = (): JSX.Element =>
{
  const navigate = useNavigate();
  const { pid } = useParams() as { pid: string };
  const { setUser, setUserType, setIsAuthenticated } = useAuth();
  const [isSubmitting, SetIsSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [pwdError, setPwdError] = useState<string>('');
  const isMagicLinkSent = false;

  const mounted = useRef(false);
  useEffect(() =>
  {
    mounted.current = true;
    return () =>
    {
      mounted.current = false;
    };
  });

  async function handleValidSubmit(values: ISetPasswordFormFields)
  {
    SetIsSubmitting(true);

    setPwdError('');
    if (values.password !== values.password_confirmation)
    {
      setPwdError(langService.Translate('Confirm Password does not match'));
      SetIsSubmitting(false);
      return;
    }

    const result: ParticipantAuthResult = await dataService.participantSetPassword(
      pid,
      values.password,
    );

    if (result.success)
    {
      const result1: ParticipantAuthResult = await dataService.authParticipant(
        pid,
        values.password,
      );
      if (result1.success)
      {
        const userObj = {
          username: pid,
          email: pid,
          userType: 'participant',
          token: result1.authToken!,
          groups: [],
        };
        cookies.set('authUser', userObj, { path: '/' });
        cookies.set('token', result1.authToken, { path: '/' });
        cookies.set('type', 'participant', { path: '/' });
        setUser(userObj);
        setUserType('participant');
        setIsAuthenticated(true);
        const IsOtherLang = LangServices.currentLanguage.short_code !== 'en';
        window.location.href = '/page/participant_start/' + pid + (IsOtherLang ? '?L=' + LangServices.currentLanguage.short_code : '');
        // navigate('/page/participant_start/' + pid);
      } else
      {
        setErrorMessage(langService.Translate(result1.errorMessage!));
      }
    } else
    {
      setErrorMessage(langService.Translate(result.errorMessage!));
    }

    // component is unmounted
    if (!mounted.current) return;
    SetIsSubmitting(false);
  }

  return (
    <Layout>
      <Formik
        enableReinitialize={true}
        initialValues={{
          password: '',
          password_confirmation: '',
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string().required(langService.Translate('Enter Password')),
          password_confirmation: Yup.string().required(langService.Translate('Enter Password Confirmation')),
        })}
        onSubmit={(values) =>
        {
          handleValidSubmit(values);
        }}
      >
        {({ errors, touched }) => (
          <Form className="form-horizontal">
            {isMagicLinkSent && (
              <>
                <h3 className="text-center mb-5">{langService.Translate('Check your email')}!</h3>
                <div className="mt-1 text-center">
                  <span>{langService.Translate('We emailed a magic link to testemail@test.com')}</span>
                  <br />
                  <span>{langService.Translate('Click the link to log in or sign up')}</span>
                </div>
              </>
            )}

            {!isMagicLinkSent && (
              <>
                <h3 className="text-center mb-5">{langService.Translate('Set Password')}</h3>
                {errorMessage && errorMessage ? <Alert color="danger">{errorMessage}</Alert> : null}

                {pwdError && pwdError ? (
                  <Alert color="danger" style={{ marginTop: '13px' }}>
                    {pwdError}
                  </Alert>
                ) : null}

                <div className="mb-3">
                  <Label for="password" className="form-label">
                    {langService.Translate('Set a password to protect your account. your password should be at least 6 letters or numbers long.')}
                  </Label>
                  <Field
                    name="password"
                    type="password"
                    id="password"
                    required
                    placeholder={langService.Translate('Password')}
                    className={
                      'form-control' + (errors.password && touched.password ? ' is-invalid' : '')
                    }
                  />
                  <ErrorMessage name="password" component="div" className="invalid-feedback" />
                </div>

                <div className="mb-3">
                  <Field
                    name="password_confirmation"
                    id="password_confirmation"
                    type="password"
                    required
                    placeholder={langService.Translate('Retype to confirm')}
                    className={
                      'form-control' +
                      (errors.password_confirmation && touched.password_confirmation
                        ? ' is-invalid'
                        : '')
                    }
                  />
                  <ErrorMessage
                    name="password_confirmation"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <div className="mt-3">
                  <button
                    className="btn btn-login btn-block waves-effect waves-light save-btn"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {langService.Translate('Set Password')}
                    {isSubmitting && <Spinner className="spinner" />}
                  </button>
                </div>
              </>
            )}
          </Form>
        )}
      </Formik>
    </Layout>
  );
};

export default SetPassword;
