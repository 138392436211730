import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Outlet, useLocation } from 'react-router-dom';

import { useAuth } from 'Common/context/AuthContext';
import { useData } from 'Common/context/DataContext';

// Layout Related Components
import Footer from './AuthLayout/Footer';
import StudyHeader from './AuthLayout/StudyHeader';

const Layout = () =>
{
  // const location = useLocation();
  // const { isAuthenticated } = useAuth();
  const { pageTitle, versionInfo, title_text } = useData();

  // useEffect(() =>
  // {
  //   if (!isAuthenticated && location.pathname !== '/')
  //   {
  //     //TODO: 'save redirectUrl when url entered on browser', location.pathname
  //   }
  // }, []);

  const sanitizedPateTitle = pageTitle + ' | V' + versionInfo?.version + ' | ' + title_text

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{sanitizedPateTitle}</title>
        <meta name="title" content={sanitizedPateTitle} />
        <meta property="og:title" content={sanitizedPateTitle} />
      </Helmet>
      <div id="layout-wrapper">
        <StudyHeader></StudyHeader>
        <div className="participant-content">
          <Outlet />
        </div>
        <Footer type={'participant'} />
      </div>
    </React.Fragment>
  );
};

export default Layout;
